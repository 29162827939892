<template>
  <div v-if="window_open == index">
    <div class="ventana-exterior">
      <div class="formulario">
        <span>¿Seguro que quieres cancelar esta mentoria?</span>
        <hr />
        <div class="button-container">
          <button @click="_closeWindow()" class="button-popup">
            No
          </button>
          <button @click="_cancelTutorial(tid, uid, duration, uuid, mentoria_email)" class="button-popup">
            SI
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import { mapMutations } from "vuex";

export default {
  mixins: [APICaller],
  name: "MentoringCancel",
  props: {
    window_open: { type: Number, required: true },
    index: {type: Number, required:true},
    tid: { type: String, required: true },
    uid: { type: String, required: true },
    duration: {type: String, required: true},
    uuid: { type: String, required: true },
    mentoria_email: { type: String, required: true },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {

    ...mapMutations(['setUserFeatures']),

    _closeWindow: function() {
      this.window_open = -1;
      this.$emit("window_close");
    },

    _cancelTutorial(tid, uid, duration, uuid, mentoria_email) {
      let params = new URLSearchParams();
      params.append("tid", tid);
      params.append("uid", uid);
      params.append("duration", duration);
      params.append("uuid_calendly", uuid);
      params.append("email", mentoria_email);
      let success = (response) => {
        if (response.data == false) {
          this.$vueOnToast.pop(
            "error",
            "Error cancelando la reserva de mentoria, intentelo de nuevo o contacte con nosotros"
          );
          this._closeWindow();
        } else {
          this.$vueOnToast.pop("success", "Tutoria cancelada con éxito");
          this.setUserFeatures(response.data);
          this.$emit("_getTutorials");
          this._closeWindow();
        }
      };

      let url = "/api/v1/eurekers-tutorials/cancel";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "339",
        "Error cancelando la tutoria, pongase en contacto con nosotros."
      );

      this._postAPICall(url, params, successHandler, failureHandler);
    },
  },
};
</script>

<style lang="scss" scoped>
.ventana-exterior {
  position: fixed;
  top: 0px;
  background: rgba(0, 0, 0, 0.45);
  width: 100%;
  height: 100%;
  z-index: 999999;
  left: 0px;
}
@media (min-width: 600px) {
  .formulario {
    text-align: center;
    width: 383px;
    height: 100px;
    background-color: white;
    padding: 30px;
    border-radius: 20px;
    margin: 40px auto 0;
    top: 50%;
    left: 50%;
  }
  .close {
    margin-left: 275px;
  }
}
@media (max-width: 600px) {
  .formulario {
    text-align: center;
    overflow: auto;
    background-color: white;
    width: 300px;
    margin: 40px auto 0;
    padding: 20px 30px;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 33%);
    transition: all 0.3s ease;
    margin-bottom: 40px;
    top: 50%;
    left: 50%;
  }
  .close {
    margin-left: 150px;
  }
  .create-portfolio1 {
    margin-top: 10px;
    margin-left: 0px !important;
  }
}
.portfolio-input {
  width: 200px;
  margin: auto;
}
.input-container {
  margin: auto;
  width: fit-content;
}
.button-options {
  text-align: center;
  margin-top: 50px;
}
.create-portfolio1 {
  margin-top: 20px;
  margin-left: 20px;
}

.button-popup {
  width: 160px;
  height: 40px;
  background-color: #00bfff;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 0 3px rgba(0, 191, 255, 0.5);
  transition: all 0.2s ease-in-out;
  bottom: 8px;
  right: 0;
  cursor: pointer;
  margin: 12px;
}


.button-container {
  position: relative;
}
</style>
