<template>
  <div
    v-if="this.dataReady"
    class="main-container"
  >
    <div class="header-tutorials">
      <h1 class="main-title">{{ "Mentoría 1:1" }}</h1>
      <div class="header-description">
        <div>
          <p class="p-desc-1">
            ¿Necesitas ayuda personalizada en tus estudios?
          </p>
          <p class="p-desc-2">
            Ofrecemos sesiones de mentoría 1:1 a través de videollamadas, donde
            podrás conectarte de manera privada con uno de nuestros tutores
            expertos para resolver problemas específicos, repasar material de la
            formación y obtener el apoyo que necesites para alcanzar tus
            objetivos.
          </p>
        </div>
        <button class="button-desc" @click="_goToStore()">
          COMPRAR SESIONES
        </button>
      </div>
    </div>

    <div class="popup-background" v-if="isBooking">
      <div class="popup-container">
        <button @click="closePopup" class="close-button">&times;</button>
        <div v-if="this.iframe_loading" id="loader" class="gif-container">
          <img src="@/assets/img/spinner.gif" width="329px" />
        </div>
        <div v-if="this.details_page_active" id="myDiv" class="blocker"></div>
        <iframe
          class="popup-iframe"
          :src="this.iframe_url"
          style="width:100%; height:100%"
          scrolling="no"
        ></iframe>
      </div>
    </div>
    <div class="filters">
      <!--tutorias disponibles-->
      <button @click="activateSelector('disponibles')" class="accordion" :class="{'expanded': this.selector_disponibles}">
        <span
          v-if="this.selector_disponibles"
          class="material-symbols-outlined flecha"
        >
          expand_more
        </span>
        <span v-else class="material-symbols-outlined flecha">
          navigate_next
        </span>
        <div class="accordion-reservar-container">
          <span class="accordion-reservar">RESERVAR MENTORÍA</span>
          <span class="saldo-disponible-container"
            >SALDO DISPONIBLE:
            <span class="saldo-disponible"
              >{{
                _g_UserFeatures.consumibles.sessions_video_tutorial_remaining
              }}
              sesiones</span
            ></span
          >
        </div>
      </button>
      <div
        class="panel-active-tutor"
        :class="{ panel: !this.selector_disponibles }"
        id="disponibles-container"
        v-if="this.selector_disponibles"
      >
        <div
          v-for="(card, index) in available_tutorials"
          :key="index"
          class="tutor-card"
        >
          <div class="card-text-container">
            <div class="card-info">
              <h4 class="tutorial-title">Reserva de Mentoría:</h4>
              <div style="margin-top: 0px !important;">
                Seleccione la cantidad de sesiones que desea realizar
              </div>
              <div class="saldo-disponible-card">
                Saldo disponible:
                {{
                  _g_UserFeatures.consumibles.sessions_video_tutorial_remaining
                }}
                sesiones
              </div>
            </div>
            <hr class="hr-class" />
            <div class="card-button-container-tutor">
              <div class="minutes-selector-container" style="width: 100%;">
                <div class="sesiones-container">
                  <div class="sesiones">
                    <div style="font-weight: 600;letter-spacing: 2px;">
                      SESIONES
                    </div>
                    <div>
                      10 minutos
                    </div>
                  </div>
                  <div class="contador-container">
                    <button
                      id="restar"
                      class="minutes-modifier-button material-symbols-outlined"
                      @click="decrement()"
                      style="float:left;"
                    >
                      remove
                    </button>
                    <span class="contador-reservas">{{ n_sesiones }}</span>
                    <button
                      id="sumar"
                      class="minutes-modifier-button material-symbols-outlined minutes-modifier-button-active"
                      @click="increment()"
                      style="float:right;"
                    >
                      add
                    </button>
                  </div>
                </div>
              </div>
              <hr class="hr-class" />
              <div class="book-button">
                <button
                  @click="
                    _checkUserPetition(
                      card.minutes_name,
                      card.teacher_uid,
                      card.name
                    )
                  "
                  class="card-button-tutor"
                >
                  VER HORARIO
                </button>
                <div :class="'advice-user-' + card.minutes_name">
                  {{
                    "No cuentas con el saldo suficiente para poder reservar, tu saldo es de " +
                      _g_UserFeatures.consumibles.sessions_video_tutorial_remaining +
                      " sesiones, accede a la tienda eurekers para adquirir mas"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mentoria-desc">
          <p>Reservar una sesión de mentoría es fácil. Simplemente elige el número de sesiones que desea realizar y a continuación el horario que mejor se adapte a tu agenda. Nuestros tutores estarán encantados de ayudarte en cada paso del camino.</p>
          <p>Recuerda que cada sesión tiene una duración de 10 minutos, pero no te preocupes si necesitas más tiempo para resolver todas tus dudas, puedes combinar varias sesiones en la reserva.</p>
        </div>
      </div>
      <!--tutorias reservadas-->
      <button @click="activateSelector('reservadas')" class="accordion" :class="{'expanded': this.selector_reservadas}">
        <span
          v-if="this.selector_reservadas"
          class="material-symbols-outlined flecha"
          >expand_more
        </span>
        <span v-else class="material-symbols-outlined flecha"
          >navigate_next
        </span>
        <span class="accordion-reservar">
          MENTORÍAS RESERVADAS
        </span>
      </button>
      <div
        class="panel"
        :class="{ 'panel-active': this.selector_reservadas }"
        id="reservadas-container"
        v-if="this.selector_reservadas"
      >
        <div v-for="(card, index) in book_tutorials" :key="index" class="card">
          <div class="card-image-container">
            <img src="@/views/assets/mentoria.jpg" :alt="card.title" />
          </div>
          <div class="card-text-container">
            <div class="card-info">
              <h3>{{ "Fecha: " + ordenarFecha(card.time) }}</h3>
              <p>{{ "Duración: " + card.duration + " sesiones" }}</p>
              <p>{{ "Descripción: " + card.description }}</p>              
            </div>
            <div class="card-button-container">
              <button
                @click="_openZoomMeeting(card.join_url)"
                class="card-button"
              >
                Acceder
              </button>
              <button @click="_openPopUp(index)" class="cancel">
                Cancelar la mentoría
              </button>
              <Tutorials_Cancelation
                :window_open="cancelation_pop_up"
                :index="index"
                :tid="card.tid"
                mentoria_email=""
                :uid="_g_UserId"
                :duration="card.duration"
                :uuid="card.uuid"
                @window_close="window_close"
                await
                @_getTutorials="_getTutorials"
              />
            </div>
          </div>
        </div>
        <div v-if="this.book_tutorials.length == 0" class="no-tutorials">
          Actualmente no tienes tutorias reservadas
        </div>
      </div>
      <!--tutorias completadas-->
      <button @click="activateSelector('completadas')" class="accordion" :class="{'expanded': this.selector_completadas}">
        <span
          v-if="this.selector_completadas"
          class="material-symbols-outlined flecha"
        >
          expand_more
        </span>
        <span v-else class="material-symbols-outlined flecha">
          navigate_next
        </span>
        <span class="accordion-reservar">
          MENTORÍAS COMPLETADAS
        </span>
      </button>
      <div
        class="panel"
        :class="{ 'panel-active': this.selector_completadas }"
        id="completadas-container"
        v-if="this.selector_completadas"
      >
        <div
          v-for="(card, index) in completed_tutorials"
          :key="index"
          class="card"
        >
          <div class="card-image-container">
            <img src="@/views/assets/mentoria.jpg" :alt="card.title" />
          </div>
          <div class="card-text-container">
            <div class="card-info">
              <h3>{{ "Fecha: " + ordenarFecha(card.time) }}</h3>
              <p>{{ "Duración: " + card.duration + " sesiones" }}</p>
              <p>{{ "Descripción: " + card.description }}</p>              
            </div>
          </div>
        </div>
        <div v-if="this.completed_tutorials.length == 0" class="no-tutorials">
          Actualmente no tienes tutorias completadas
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tutorials_Cancelation from "@/views/Mentorias/Mentorias_popup_cancelation.vue";
import APICaller from "@/components/codeComponents/APICaller.js";
import { mapGetters } from "vuex";

export default {
  name: "Mentorias",
  mixins: [APICaller],
  components: {
    Tutorials_Cancelation,
  },
  data() {
    return {
      selector_disponibles: true,
      selector_reservadas: false,
      selector_completadas: false,
      n_sesiones: 1,
      available_tutorials: [
        {
          name: "Dani López",
          minutes_name: "minutes_dani",
          teacher_uid: 8690,
        },
      ],
      book_tutorials: [],
      completed_tutorials: [],
      link_to_store: process.env.VUE_APP_API + "/app/store",
      isBooking: false,
      iframe_url: "",
      iframe_loading: true,
      details_page_active: false,
      user_details: "",
      dataReady: false,
      cancelation_pop_up: -1,
      minutes_tutor: {
        minutes_ruben: 10,
        minutes_dani: 10,
      },
      advice_user: false,
      help_visibility: false,
    };
  },
  async mounted() {
    await this._getTutorials();
    // Llama al método para obtener los detalles del usuario
    await this._getUserInfo();

    var that = this;
    window.addEventListener("message", function(event) {
      if (event.data === "iframe ready") {
        that.iframe_loading = false;
      }
      if (event.data === "calendly.date_and_time_selected") {
        that.details_page_active = true;
      }
    });
    this.dataReady = true;
  },
  computed: {
    ...mapGetters(["_g_UserId", "_g_User", "_g_UserFeatures"]),
  },

  methods: {
    _goToStore: function() {
      window.location.href = this.link_to_store;
    },

    _updateUserFeaturesTutorials: function() {
      let success = (response) => {
        this.setUserFeatures(response.data);
      };

      let url =
        "/api/v1/eurekers-user-features/get-user-features/" +
        String(this._g_UserId);

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "314",
        "Error al recuperar los atributos de usuario."
      );

      this._getAPICall(url, successHandler, failureHandler);
    },

    _changeHelpVisibility: function() {
      this.help_visibility = !this.help_visibility;
    },

    ordenarFecha(fecha) {
      var [fechaParte, horaParte] = fecha.split("  ");
      var [mes, dia, anio] = fechaParte.split("/");
      var [hora, minutos, segundos] = horaParte.split(":");

      return `${dia}/${mes}/${anio} ${hora}:${minutos}`;
    },

    increment() {
      let sumar = document.getElementById("sumar");
      let restar = document.getElementById("restar");
      let n_sesiones_nuevo = this.n_sesiones + 1;

      if(n_sesiones_nuevo <= 5){
        this.n_sesiones = n_sesiones_nuevo;
        restar.classList.add("minutes-modifier-button-active");
        if(n_sesiones_nuevo < 5){
          sumar.classList.add("minutes-modifier-button-active");  
        }
        else{
          sumar.classList.remove("minutes-modifier-button-active");
        }
      }
      
    },

    decrement() {
      let sumar = document.getElementById("sumar");
      let restar = document.getElementById("restar");
      let n_sesiones_nuevo = this.n_sesiones - 1;

      if(n_sesiones_nuevo >= 1){
        this.n_sesiones = n_sesiones_nuevo;
        sumar.classList.add("minutes-modifier-button-active");
        if(n_sesiones_nuevo > 1){
          restar.classList.add("minutes-modifier-button-active");  
        }
        else{
          restar.classList.remove("minutes-modifier-button-active");
        }
      }

    },
    _openPopUp(index) {
      this.cancelation_pop_up = index;
    },
    _openZoomMeeting: function(url) {
      window.open(url, "_blank");
    },
    window_close() {
      this.cancelation_pop_up = -1;
    },
    activateSelector(filter) {
      const dictSelectors = {
        disponibles: () => {
          this.selector_disponibles = !this.selector_disponibles;
        },
        reservadas: () => {
          this.selector_reservadas = !this.selector_reservadas;
        },
        completadas: () => {
          this.selector_completadas = !this.selector_completadas;
        },
      };
      dictSelectors[filter]();
    },
    async _getUserInfo() {
      let success = (response) => {
        if (response.data != undefined) {
          this.user_details = response.data;
          this.iframe_url =
            process.env.VUE_APP_API +
            "/eurekers-tutorials/bookTutorial?name=" +
            this.user_details.name +
            "&email=" +
            this.user_details.email;
        }
      };

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "338",
        "Error al recuperar la informacion del usuario."
      );

      let url = "/api/v1/eurekers-tutorials/user-details";

      this._getAPICall(url, successHandler, failureHandler);
    },
    async _getTutorials() {
      this.book_tutorials = [];
      this.completed_tutorials = [];

      var uid = this._g_UserId;

      let success = (response) => {
        var that = this;
        response.data.forEach(function(element) {
          if (element.status == 1) {
            that.book_tutorials.push(element);
          }
          if (element.status == 2) {
            that.completed_tutorials.push(element);
          }
        });
      };

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "333",
        "Error al recuperar las tutorias disponibles de usuario."
      );

      let url = "/api/v1/eurekers-tutorials/user/" + uid;

      this._getAPICall(url, successHandler, failureHandler);
    },

    _openCalendly() {
      this.isBooking = !this.isBooking;
    },

    async _checkUserPetition(name, teacher_uid, teacher_name) {
      if (
        this._g_UserFeatures.consumibles.sessions_video_tutorial_remaining >=
        this.n_sesiones
      ) {
        document.getElementsByClassName(
          "advice-user-" + name
        )[0].style.display = "none";
        await this._createTutorial(
          teacher_uid,
          teacher_name,
          this.n_sesiones
        );
      } else {
        document.getElementsByClassName(
          "advice-user-" + name
        )[0].style.display = "block";
      }
    },

    _encryptDuration(duration) {
      var encodedDuration = btoa(duration.toString());
      return encodedDuration;
    },

    async _createTutorial(teacher_uid, teacher_name, duration) {
      var uid = this._g_UserId;

      let params = new URLSearchParams();
      params.append("uid", uid);
      params.append("teacher", teacher_uid);
      params.append("teacher_name", teacher_name);
      params.append("duration", duration);

      teacher_name = teacher_name
        .toLowerCase()
        .replace(/\s+/g, "-")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      this.iframe_url +=
        "&tion=" +
        this._encryptDuration(duration) +
        "&teacher_name=" +
        teacher_name;

      let success = (response) => {
        this._openCalendly();
      };

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "395",
        "Error al reservar la tutoria"
      );

      let url = "/api/v1/eurekers-tutorials/create";

      this._postAPICall(url, params, successHandler, failureHandler);
    },

    closePopup() {
      this.iframe_loading = true;
      this.isBooking = false;
      this.details_page_active = false;
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.main-container {
  display: inline-block;
  margin-left: 4%;
  margin-right: 8%;
  margin-top: 5%;
}

#disponibles-container, #reservadas-container, #completadas-container {
  background-color: var(--color-surface);
}

.filters {
  margin-bottom: 1em;
}

.filter {
  display: inline-block;
  margin-right: 1em;
}

.no-tutorials {
  display: inline-block;
  margin: 1em;
  overflow: hidden;
  color: var(--color-text-secondary);
}

.tutor-card {
  display: inline-block;
  margin: 1em;
  width: 20%;
  border: 2px solid #51515a;
  border-radius: 5px;
  overflow: hidden;
  flex-shrink: 0;
  max-width: 350px;
  min-width: 250px;
}

.mentoria-desc{
  margin-left: 40px;
  color: var(--color-text-secondary);
}

@media only screen and (max-width: 1068px) {
  .tutor-card {
    display: block;
    width: 100%;
    margin: 10px !important;
  }

  .card{
    margin: 8px !important;
  }

  .mentoria-desc{
    margin-left: 0px !important;
  }


}

.card {
  display: inline-block;
  width: 20%;
  margin: 1em;
  border: 1px solid #ddd;
  border-radius: 5px;
  border: 2px solid #51515a;
  overflow: hidden;
}
ñpÀQOP ERYº1 .card:hover {
  box-shadow: 0 0 10px rgba(79, 79, 79, 0.4); /* agregar sombra */
  transition: all 0.3s ease-in-out; /* agregar transición */ /* aumentar la escala en un 3% */
  margin-top: 20px;
}

@media only screen and (max-width: 768px) {
  /* Ajustar el punto de corte según sea necesario */
  .card {
    display: block;
    width: 100%;
  }
}

.card-info {
  width: 100%;
  margin-bottom: 20px;
}

.card-info p {
  overflow: hidden;
  word-wrap: break-word;
  margin-top: 5px !important;
  margin-bottom: 10px !important;
}

.card-info * {
  color: var(--color-text-secondary);
}

.card-image-container {
  height: 100px;
  overflow: hidden;
  background-color: #ffffff;
  position: relative;
  padding: 11px;
  padding-left: 13px;
  padding-right: 13px;
}

.card-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-image-container span {
  position: absolute;
  top: 15%;
  left: 95%;
  transform: translate(-50%, -50%);
  color: white;
}

.card-image-container span:hover {
  cursor: pointer;
}

.card-text-container {
  padding: 10px;
  padding-right: 2em;
  padding-left: 2em;
  height: 90%;
}

.card-text-container h2 {
  font-size: 1.2em;
  color: #00bfff;
}

.card-text-container h3 {
  font-size: 1em;
  color: #4d4d4d;
  font-family: "Open-Sans", sans-serif;
  font-size: 16px !important;
  margin-top: 10px;
}

.card-text-container p {
  font-size: 1em;
  color: #4d4d4d;
  font-family: "Open-Sans", sans-serif;
  font-size: 16px !important;
}

input[type="radio"] {
  margin-right: 0.5em;
}

.accordion {
  background-color: var(--color-surface);
  color: var(--color-text-secondary);
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 18px;
  transition: 0s;
  display: inline-flex;
  border-radius: 14px;
  margin-top: 8px;
}

.active,
.accordion:hover {
  background-color: var(--color-active-surface);
}

.panel {
  padding: 0 18px;
  display: none;
  background-color: white;
  overflow: hidden;
}

.panel-active {
  padding: 0 18px;
  display: flex;
  background-color: white;
  overflow: hidden;
  flex-wrap: wrap;
}

.panel-active-tutor {
  padding: 0 18px;
  background-color: white;
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

@media only screen and (max-width: 768px) {
  .panel-active-tutor{
    display: block;
  }
}



.tutor-card:hover {
  box-shadow: 0 0 10px rgba(79, 79, 79, 0.4); /* agregar sombra */
  transition: all 0.3s ease-in-out; /* agregar transición */
  transform: scale(1.03); /* aumentar la escala en un 3% */
  margin-top: 20px;
}

@media only screen and (max-width: 768px) {
  /* Ajustar el punto de corte según sea necesario */
  .panel-active {
    display: block;
    width: 80%;
  }
}

.flecha {
  margin-right: 8px;
}

.minutes-modifier-button {
  border: none;
  background-color: #ceced0;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px !important; /* Ajusta el tamaño de fuente según tus preferencias */
  font-weight: 500;
  width: 25px; /* Ancho deseado del botón */
  height: 25px; /* Alto deseado del botón */
  line-height: 25px; /* Ajusta la línea de altura según tus preferencias */
  padding: 0px; /* Ajusta el padding según tus preferencias */
  border-radius: 5px; /* Ajusta el valor para cambiar la curvatura de las esquinas */
  box-sizing: border-box; /* Incluye el padding en el ancho y alto total del botón */
}


.minutes-modifier-button-active {
  background-color: #00bfff !important;
}

.minutes-modifier-button:first-child {
  margin-right: 16px;
}

.minutes-modifier-button:last-child {
  margin-left: 16px;
}

.card-button-container {
  display: block;
}

.minutes-selector-container {
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
}

.card-button-tutor {
  background-color: #00add5;
  color: white;
  border: none;
  border-radius: 28px;
  padding: 10px 46px;
  font-size: 14px;
  line-height: 16px;
  box-shadow: 0 0 3px rgba(0, 191, 255, 0.5);
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  margin: 10px 0 6px 0;
  white-space: normal;
}

.card-button-tutor:hover {
  background-color: rgb(0, 176, 215);
  color: #fff;
}

.card-button {
  background-color: #00add5;
  color: white;
  border: none;
  border-radius: 28px;
  padding: 10px 46px;
  font-size: 14px;
  line-height: 16px;
  box-shadow: 0 0 3px rgba(0, 191, 255, 0.5);
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  margin: 0px 0 6px 0;
  white-space: normal;
  text-transform: uppercase;
}

.card-button:hover {
  background-color: #01bbe4;
  color: #fff;
}

button.cancel {
  background: none;
  border: none;
  color: #f24265;
  cursor: pointer;
}
button.cancel:hover {
  color: #f14668;
}

.cancel {
  font-size: 13px;
  bottom: 8px;
  right: 0;
}


.popup-background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Fondo con transparencia */
  z-index: 999;
}

.popup-container {
  position: absolute;
  top: 50%;
  left: 51%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%; /* El contenedor ocupa el 90% de la altura de la ventana del navegador */
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5); /* Sombra */
}

.close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 30px;
  margin: 0;
  padding-top: 0;
  background-color: transparent;
  border: none;
  outline: none;
  color: grey;
}

.close-button:hover {
  cursor: pointer;
  color: rgb(93, 93, 93);
}

.popup-iframe {
  border: none;
}

.gif-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.blocker {
  z-index: 999;
  width: 450px;
  height: 248px;
  position: absolute;
  top: 167px;
  left: 602px;
}

@media only screen and (max-width: 1508px) {
  /* Ajustar blocker name y correo */
  .blocker {
    z-index: 999;
    width: 438px;
    height: 279px;
    position: absolute;
    top: 152px;
    left: 437px;
  }
}

@media only screen and (max-width: 1232px) {
  /* Ajustar blocker name y correo */
  .blocker {
    z-index: 999;
    width: 438px;
    height: 228px;
    position: absolute;
    top: 152px;
    left: 265px;
  }
}

@media only screen and (max-width: 967px) {
  /* Ajustar blocker name y correo */
  .blocker {
    z-index: 999;
    width: 443px;
    height: 280px;
    position: absolute;
    top: 100px;
    left: 175px;
  }
}

@media only screen and (max-width: 732px) {
  /* Ajustar blocker name y correo */
  .blocker {
    z-index: 999;
    width: 443px;
    height: 310px;
    position: absolute;
    top: 102px;
    left: 89px;
  }
}

@media only screen and (max-width: 600px) {
  /* Ajustar blocker name y correo */
  .blocker {
    z-index: 999;
    width: 361px;
    height: 310px;
    position: absolute;
    top: 70px;
    left: 34px;
  }
}

@media only screen and (max-width: 490px) {
  /* Ajustar blocker name y correo */
  .blocker {
    z-index: 999;
    width: 280px;
    height: 310px;
    position: absolute;
    top: 70px;
    left: 34px;
  }
}

.book-button {
  text-align: center;
  margin-top: 34px;
  margin-bottom: 10px;
}


.tutorial-title {
  font-size: 18px !important;
  font-weight: 700;
  margin-bottom: 5px;
}

.main-title {
  text-align: left;
  font-family: "Open Sans", sans-serif;
  font-size: 20px !important;
  color: var(--color-text-primary);
  font-weight: 600;
}

@media (max-width: 600px) {
  .main-title {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 23px !important;
  }
}

.p-desc-1 {
  font-weight: 700;
  font-size: 14px;
  color: var(--color-text-primary);
  margin-bottom: 0px !important;
}

.p-desc-2 {
  font-weight: 400;
  font-size: 14px;
  color: var(--color-text-primary);
  margin-top: 2px !important;
}

.button-desc {
  background-color: #009cc8;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 9px 45px;
  font-size: 13px;
  line-height: 15px;
  box-shadow: 0 0 3px rgba(0, 191, 255, 0.5);
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  margin: 10px 0 6px 0;
  white-space: normal;
  font-family: "Open Sans", sans-serif;
}

.button-desc:hover {
  background-color: #039fca;
  color: #fff;
}

.shop-hover:hover {
  box-shadow: 0 0 10px rgba(79, 79, 79, 0.4); /* agregar sombra */
  transition: all 0.3s ease-in-out; /* agregar transición */
  transform: scale(1.03); /* aumentar la escala en un 3% */
  margin-top: 20px;
}

@keyframes move {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-5px);
  }
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(5px);
  }
}

.header-tutorials {
  margin-bottom: 33px;
}

.advice-user-minutes_dani {
  display: none;
  color: red;
  font-size: 12px;
  animation: move 0.5s 3;
}

@media (max-width: 600px) {
  .header-tutorials span {
    position: relative;
    right: auto;
  }
}

.accordion-reservar-container {
  width: 100%;
}

.accordion-reservar {
  font-weight: 700;
  font-size: 15px;
}

.saldo-disponible-container {
  font-weight: 700;
  font-size: 15px;
  float: right;
}

@media (max-width: 600px){
  .saldo-disponible-container{
    float: left;
    margin-top: 12px;
  }
}

.saldo-disponible {
  font-weight: 700;
  font-size: 15px;
  color: #56ab75;
}

.saldo-disponible-card {
  font-weight: 400;
  font-size: 14px;
  color: #56ab75;
  margin-top: 20px;
}

.hr-class {
  background-color: #d0d0d2;
  height: 0.5px;
  border: none;
  margin-top: 12px;
}

.sesiones-container{
  display: flex;
  justify-content: space-between !important;
}

.sesiones-container * {
  color: var(--color-text-secondary);
}

.sesiones {
  display: flex;
  flex-direction: column;
}

.expanded {
  border-radius: 14px 14px 0 0;
  background-color: var(--color-active-surface);
}

.contador-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 600px){
  .header-description{
    text-align: justify;
  }

  .header-description button{
    display: block;
    margin: 0 auto;
  }
}

</style>
